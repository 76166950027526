import * as React from "react";

export default function IconFigcaption() {
  return (
    <span className="icon-figcaption">
      <svg 
        width="20" 
        height="18" 
        viewBox="0 0 20 18" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg">
          <path 
            d="M10 13c1.6569 0 3-1.3431 3-3 0-1.65685-1.3431-3-3-3-1.65685 0-3 1.34315-3 3 0 1.6569 1.34315 3 3 3z" 
            fill="#848383"/>
          <path 
            d="M7 0L5.17 2H0v16h20V2h-5.17L13 0H7zm3 15c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z" 
            fill="#848383"/>
      </svg>
    </span>
  )
}