import React, { useState, useEffect } from 'react'
import Update from '../components/Update'
import { Link, useStaticQuery, graphql } from "gatsby"

const Column = ({ id, frontmatter } ) => {
  if( !frontmatter.update_headline ) {
    return;
  }

  return(
    <div className="col-md-4">
      <Update id={ id } image={`../${frontmatter.update_image}`} headline={ frontmatter.update_headline } intro={ frontmatter.update_intro } />
    </div>
  )
}

export default function PaginateUpdates ({ sectionHeadline }) {
  const [offset, setOffset] = useState(0);
  const [updates, setUpdates] = useState([]);

  // = = = = = = = = = = = = =
  // Hooks

  // Get more updates when offset state changes
  useEffect( () => getUpdates(), [offset]);


  // = = = = = = = = = = = = =
  // GraphQL

  const data = useStaticQuery(graphql`
    query Updates {
      updates: allMarkdownRemark(
        filter: {frontmatter: {type: {eq: "update"}}}
        sort: {fields: frontmatter___date_published, order: DESC}
      ) {
        nodes {
          frontmatter {
            title
            update_headline
            update_image
            update_intro
          }
          id
        }
      }
    }
  `)

  const initialUpdates = data.updates.nodes;


  // = = = = = = = = 
  // Events Functions

  //
  const handleClick = (e) => {
    e.preventDefault();
    setOffset(offset + 3);
  }

  //
  const getUpdates = () => {
    setUpdates(initialUpdates.slice(0, offset + 3));
  }

  return(
    <section id="paginated-updates" className={`paginated-updates ${ sectionHeadline ? 'paginated-updates--bg' : '' } `}>
      
      <div className="container">

        { sectionHeadline ? <h3 className="paginated-updates__headline">{ sectionHeadline }</h3> : '' }

        <div className="row">
          { updates.map( update => <Column key={ update.id } id={update.id} frontmatter={update.frontmatter} /> ) }
        </div>

        <div className="row">
          <div className="col text-center">
            {
              initialUpdates.length - offset
               ? <Link onClick={ (e) => handleClick(e) } className="load-more" to="/updates">More</Link>
               : <Link>No more updates</Link>
            }
          </div>
        </div>
            
      </div>
    </section>
  )
}