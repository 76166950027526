import React from 'react'
import { graphql } from 'gatsby'
import { StaticImage } from "gatsby-plugin-image"

import Seo from "../components/Seo"
import Hero from "../components/Hero"
import SignUpForm from '../components/forms/SignUpForm'
import PaginatedUpdates from '../components/PaginatedUpdates'
import IconFigcaption from '../components/icons/IconFigcaption'
import SignUpSection from '../components/SignUpSection'

export default function Post({ data }) {
  const page = data.updates.frontmatter;
  const post = data.post.frontmatter;
  const article = data.post.html;
  
  const postDate = new Date(post.date_published);
  const postDateMonth = postDate.toLocaleString('default', { month: 'long' });

  return (
    <>
      <Seo title={`Update: ${ post.update_headline }`} />

      <Hero 
        headline = "Updates / Page Title"
        image = {page.hero_background_image}
        size = "small"
      >
        <StaticImage 
          className="hero__picture"
          src="../../static/media/updates-hero.jpg" 
          alt=""
        />
      </Hero>

      <section className="container">

        <article className="post">

          <div className="row date-wrapper">
            <div className="col-md-8 offset-md-2">
              <p className="post__date">{`${postDateMonth} ${postDate.getDay()}, ${postDate.getFullYear()}`}</p>
              <hr />
            </div>
          </div>

          <div className="row title-wrapper">
            <div className="col-md-8 offset-md-2">
              <h1 className="post__title">{ post.update_headline }</h1>
            </div>
          </div>

          <div className="row intro-wrapper">
            <div className="col-md-8 offset-md-2">
              <p className="post__intro">{ post.update_intro } </p>
            </div>
          </div>

          <figure className="post__figure row">
            <figcaption className="figure__figcaption col-md-2">
              <IconFigcaption />
              { post.update_image_caption }
            </figcaption>
            <img  className="figure__image col-md-10" src={`../${post.update_image}`} alt={ post.update_image_caption } />
          </figure>

          <div className="row content-wrapper">
            <div className="col-md-8 offset-md-2 post__content" dangerouslySetInnerHTML={{__html: article }}/>
          </div>
          
        </article>

        <div className="row">
          <div className="col-md-8 offset-md-2">
            <div className="form-wrapper form--article">
              <SignUpForm heading="Get updates about the Ward elections" type="article" />
            </div>
          </div>
        </div>

      </section>


      <PaginatedUpdates sectionHeadline = { 'Related updates' } />

      <SignUpSection />
    </>
  )
}

export const query = graphql`
  query Update($id: String!) {
    updates: markdownRemark(frontmatter: {page_title: {eq: "Updates"}}) {
      frontmatter {
        hero_background_image{
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }

    post: markdownRemark(id: {eq: $id}) {
      html
      frontmatter {
        title
        update_tags
        update_image
        update_intro
        date_published
        update_headline
        update_image_caption
      }
    }
  }
`;